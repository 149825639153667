<template>
  <b-modal
    ref="modal-pre-order-import"
    hide-footer
    :title="titleExcel"
    id="modal-pre-order-import"
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <span> <b>Bước 2:</b> Tiến hành Import </span>
      <b-row>
        <b-col>
          <UploadFileMulter
            v-on:send-file="getDataFromExcel"
            v-on:clear-file="clearFileExcel"
            :cmdUrl="'pre-orders/import-excel/'"
          />
          <span v-if="fileName"
            >Tên file: <b>{{ fileName }}</b>
          </span>
        </b-col>
      </b-row>
      <!-- End input session -->
    </div>
    <div class="mt-10" v-show="importItems.length !== 0">
      <hr
        class="hr-text"
        data-content="Danh sách đơn đặt trước"
        style="font-weight: 600"
      />
      <div class="title-sum">
        <ul class="list-inline d-flex">
          <li>
            <b
              >Tổng tiền:
              <span class="text-success">{{ formattedTotalPrice }}</span>
            </b>
          </li>
        </ul>
      </div>
      <b-table
        class="myTable"
        caption-top
        responsive
        bordered
        hover
        :fields="fields"
        :items="importItems"
        id="my-table"
        :per-page="10"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle ml-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(customerName)="data">
          <b> {{ data.item.customerName }}</b>
          <p>{{ data.item.customerMobile }}</p>
          <span v-if="data.item.customerEmail">
            <b>Email:</b> {{ data.item.customerEmail }}
          </span>
          <span v-if="data.item.customerAddress">
            <b>ĐC:</b> {{ data.item.customerAddress }}
          </span>
        </template>
        <template v-slot:cell(productName)="row">
          <p
            v-if="row.item.productCode"
            style="font-size: 12px; font-weight: 500; margin-bottom: 0"
          >
            ( {{ row.item.productCode }} )
          </p>
          <span
            v-text="row.item.productName"
            style="white-space: normal; color: #3699ff"
          ></span>
        </template>

        <template #cell(statusValidate)="data">
          <div v-if="!data.item.onLoading">
            <div class="d-table-cell align-middle" style="width: 10%">
              <i
                v-b-tooltip.hover
                :title="htmlGetStatusValidate(data.item, 'title')"
                :class="htmlGetStatusValidate(data.item, 'class')"
              ></i>
            </div>
            <div class="d-block align-middle pb-1 pl-2" style="width: 90%">
              <div v-for="(items, i) in data.item.errors" :key="i">
                <span class="text-danger">{{ items }}</span>
              </div>
            </div>
            <div class="d-table-cell align-middle pb-1 pl-2" style="width: 90%">
              {{ data.item.statusValidateName }}
            </div>
          </div>
          <div v-else>
            <b-spinner small variant="primary" class="mr-2"></b-spinner>
            <span class="text-primary">Đang xử lí...</span>
          </div>
        </template>

        <template #cell(actions)="row">
          <v-icon
            v-if="!hasError || successCreateOrder"
            small
            class="text-danger"
            @click="onRemovedItem(row.item.orderCode)"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </template>
      </b-table>
      <b-col class="row mt-3">
        <b>
          Tổng số dòng:
          {{ importItems ? importItems.length : 0 }}
        </b>
      </b-col>
      <b-pagination
        v-show="importItems.length > 10"
        v-model="currentPage"
        :total-rows="rows()"
        :per-page="10"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </div>
    <b-overlay
      v-if="!hasError && importItems.length"
      :show="onLoadingList"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button
        style="width: 80px"
        class="mt-6"
        :disabled="onLoadingList || !importItems.length"
        v-if="importItems.length && !successCreateOrder"
        variant="primary"
        size="sm"
        @click="onCreatePreOrders"
      >
        <strong>Tạo mới</strong>
      </b-button>
    </b-overlay>

    <b-button
      class="mt-6"
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModalExcel"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>
<script>
import { BASE_URL } from '@/utils/constants';
import { cloneDeep, convertPrice, makeToastFaile } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import { makeToastSuccess } from '../../../utils/common';

const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  READY: 2,
  LOADING: 3,
};

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  name: 'PreOrderImportExcel',
  data() {
    return {
      titleExcel: 'Nhập excel đơn đặt trước',
      urlExcel: BASE_URL + 'media/upload/pre-orders/Temp_Import_PreOrder_.xlsx',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      importItems: [],
      isBusy: false,
      successCreateOrder: false,
      fileName: '',
      fields: [
        {
          key: 'orderSourceName',
          label: 'Nguồn ĐH',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'formName',
          label: 'Chương trình',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            ...thStyleCommon,
            width: '30%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'color',
          label: 'Màu sắc',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'size',
          label: 'Dung lượng',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'productPrice',
          label: 'Số tiền',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'customerName',
          label: 'Tên khách hàng',
          thStyle: {
            ...thStyleCommon,
            width: '7%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'customerCurrentPhone',
          label: 'Đang sở hữu',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'installMoneyAmount',
          label: 'Trả trước',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'statusValidate',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'align-middle text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle text-center',
        },
      ],
    };
  },
  components: {
    UploadFileMulter,
  },
  computed: {
    hasError() {
      const errors = this.importItems.map((item) => item.errors).flat();
      return errors.length > 0;
    },
    formattedTotalPrice() {
      let totalPrice = 0;
      this.importItems.map((item) => {
        const totalPriceItem = item.productPrice;
        totalPrice += totalPriceItem;
      });
      return this.convertPrice(totalPrice);
    },
  },
  methods: {
    convertPrice,
    hideModalExcel() {
      this.$refs['modal-pre-order-import'].hide();
      this.clearFileExcel();
    },
    onCreatePreOrders: async function () {
      const items = cloneDeep(this.importItems);
      const invalids = items.filter((item) => item.errors.length > 0);
      if (invalids.length > 0) {
        return makeToastFaile(
          `Đơn hàng không hợp lệ 
          ${invalids.map((item) => item.orderCode).join()}`,
        );
      }

      if (!this.isBusy) {
        this.onLoadingList = true;
        const dataMessages = {
          success: [],
          failed: [],
        };
        let index = 0;
        for (const item of items) {
          this.importItems[index].onLoading = true;

          const [status, message] = await ApiService.post('/pre-orders', item)
            .then((response) => {
              const { status, message } = response.data;
              return [status, message];
            })
            .catch((err) => {
              if (!err.response) {
                // network error
                return [0, 'Lỗi mạng'];
              } else {
                const message = err.response.data.message;
                return [0, message];
              }
            });

          this.importItems[index].onLoading = false;

          if (status) {
            dataMessages.success.push(item);
            this.importItems[index].statusValidate = STATUS_VALIDATE.VALID;
            this.importItems[index].statusValidateName = 'Tạo mới thành công';
          } else {
            this.importItems[index].statusValidate = STATUS_VALIDATE.INVALID;
            this.importItems[index].statusValidateName = message;
          }
          index++;
        }
        if (dataMessages.success.length === this.importItems.length) {
          makeToastSuccess('Tạo đơn đặt trước thành công!');
          this.successCreateOrder = true;
          this.hideModalExcel();
          this.$emit('onRefresh', true);
        } else {
          makeToastFaile('Import đơn đặt trước chưa hoàn tất!');
        }
      }
    },
    rows() {
      return this.importItems.length;
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class' ? 'far fa-clock text-primary' : 'Sẵn sàng tạo mới';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-success'
              : 'Sẵn sàng tạo mới';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.importItems = [...data.rows].map((item) => {
        const statusValidate =
          item.errors && item.errors.length > 0
            ? STATUS_VALIDATE.INVALID
            : STATUS_VALIDATE.READY;
        return {
          ...item,
          onLoading: false,
          statusValidate: statusValidate,
        };
      });
      this.onLoadingList = false;
      this.successCreateOrder = false;
    },
    clearFileExcel() {
      this.importItems = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
  },
};
</script>

<style lang="scss">
.modal-pre-order-import {
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}
</style>
